@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Black.woff2') format('woff2'),
    url('/fonts/Avenir-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Medium.woff2') format('woff2'),
    url('/fonts/Avenir-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Heavy.woff2') format('woff2'),
    url('/fonts/Avenir-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Light.woff2') format('woff2'),
    url('/fonts/Avenir-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Regular.woff2') format('woff2'),
    url('/fonts/Avenir-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

